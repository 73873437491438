<template>
  <div class="coverage-selector">
    <div
      class="slider-container"
      v-for="careType in careTypes"
      :key="careType"
      :id="`slider-${careType.toLowerCase().replaceAll(' ', '-')}`"
    >
      <v-slider
        v-model="coverage[careType].radius"
        :label="careType"
        :color="typeOptions[careType].color"
        max="60"
        min="1"
        thumb-label="always"
        :disabled="coverage[careType].complete_coverage"
        class="slider"
      >
        <template v-slot:thumb-label="{ value }">
          <span :style="{ color: typeOptions[careType].thumbTextColor }">{{
            value
          }}</span>
        </template>
      </v-slider>
      <v-checkbox
        v-model="coverage[careType].complete_coverage"
        label="All of England"
        color="primary"
        hide-details
      ></v-checkbox>
    </div>

    <gmap-map
      :center="center"
      :zoom="zoom"
      ref="map"
      :options="mapOptions"
      style="width: 100%; height: 400px;"
      v-if="lat && lng"
    >
      <gmap-circle
        v-for="careType in careTypesToShow"
        :key="careType"
        :editable="false"
        :draggable="false"
        :radius="radiusInMeters(coverage[careType].radius)"
        :center="center"
        :options="typeOptions[careType]"
      />
    </gmap-map>
    <div v-else class="map-placeholder">
      <strong>Add your address above.</strong>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    lat: { type: Number, default: null },
    lng: { type: Number, default: null },
    careTypes: { type: Array, default: () => [] },
    value: { type: Object, default: null }
  },
  data() {
    return {
      coverage: {
        "Hourly Care": {
          radius: 5,
          complete_coverage: false
        },
        "Live In Care": {
          radius: 15,
          complete_coverage: false
        },
        "Night Time Care": {
          radius: 10,
          complete_coverage: false
        }
      },
      // Used to remove the UI and prevent the user interacting with the map
      // map interaction is limited to the sliders and checkbox
      mapOptions: {
        disableDefaultUI: true,
        gestureHandling: "none"
      },
      // This contains the options for styling sliders and circles for each care type
      // they each have a zIndex so that they appear on top of each other in a consistent order
      // rather than the order they are selected. This allows us to achieve better contrast
      typeOptions: {
        "Hourly Care": {
          color: "oliveGreen lighten-3",
          thumbTextColor: "black",
          strokeColor: "#ffff80",
          strokeOpacity: 0.8,
          strokeWeight: 2,
          fillColor: "#ffff80",
          fillOpacity: 0.35,
          zIndex: 3
        },
        "Live In Care": {
          color: "red lighten-2",
          thumbTextColor: "black",
          strokeColor: "#ff9371",
          strokeOpacity: 0.8,
          strokeWeight: 2,
          fillColor: "#ff9371",
          fillOpacity: 0.35,
          zIndex: 1
        },
        "Night Time Care": {
          color: "purple darken-4",
          thumbTextColor: "white",
          strokeColor: "#4d1f4f",
          strokeOpacity: 0.8,
          strokeWeight: 2,
          fillColor: "#4d1f4f",
          fillOpacity: 0.15,
          zIndex: 2
        }
      }
    }
  },
  computed: {
    // Returns the center of the map
    center() {
      return {
        lat: this.lat,
        lng: this.lng
      }
    },
    // Takes the care types passed in via the prop and manages
    // determining whether to show it based on if they're complete coverage
    careTypesToShow() {
      return this.careTypes.filter(careType => {
        return !this.coverage[careType].complete_coverage
      })
    },
    // Get the largest radius by pushing the covered care types'
    // radii into a list and then sorting this list so that
    // largest is first, then taking the first element of the list
    // Defaulting to 10 if no first element as this is our most zoomed in
    largestRadius() {
      const radiusList = []
      for (let careType of this.careTypes) {
        radiusList.push(this.coverage[careType].radius)
      }
      radiusList.sort((a, b) => b - a)

      return radiusList[0] || 10
    },
    // Handles automatically zooming the map based on the largest radius.
    zoom() {
      const radius = this.largestRadius

      if (radius < 10) {
        return 10
      } else if (radius < 20) {
        return 9
      } else if (radius < 45) {
        return 8
      } else if (radius < 85) {
        return 7
      } else if (radius < 175) {
        return 6
      } else {
        return 5
      }
    }
  },
  methods: {
    // we're using radius in miles and google maps uses meters
    // 1 mile = 1609.34 meters
    radiusInMeters(radius) {
      return radius * 1609.34
    }
  },
  watch: {
    // Merge the `v-model` prop into the data coverage object
    // so that we can use it
    value: {
      immediate: true,
      deep: true,
      handler() {
        this.coverage = Object.assign(this.coverage, this.value)
      }
    },
    // If careTypes passed to this changes we want to update the coverage object with the defaults
    // and emit that to the parent, so that if the company is hapyp with the default coverage,
    // it will actually be saved
    careTypes: {
      deep: true,
      handler() {
        const coverage = Object.fromEntries(
          Object.entries(this.coverage).filter(([careType]) => {
            return this.careTypes.includes(careType)
          })
        )
        this.$emit("input", coverage)
      }
    },
    // On any change to coverage we want to emit this to the parent so that it can be saved.
    coverage: {
      deep: true,
      handler(newVal, oldVal) {
        if (newVal === oldVal) {
          return
        }
        const coverage = Object.fromEntries(
          Object.entries(this.coverage).filter(([careType]) => {
            return this.careTypes.includes(careType)
          })
        )
        this.$emit("input", coverage)
      }
    }
  }
}
</script>

<style scoped>
.slider-container {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  margin-bottom: 20px;
  margin-top: 30px;
}
.slider {
  display: flex;
  align-items: center;
}
.map-placeholder {
  width: 100%;
  height: 400px;
  background-color: var(--v-lightGrey-base);
  display: flex;
  justify-content: center;
  align-items: center;
}

::v-deep .slider > .v-input__control > .v-input__slot {
  margin-bottom: 0;
}

::v-deep .slider > .v-input__control > .v-messages {
  min-height: 0;
  height: 0;
}

::v-deep .v-input--selection-controls {
  margin-top: 0;
  padding-top: 0;
  margin-left: 4px;
}
</style>
